<template>
  <div id="PolicyList" style="height: 100%">
    <div style="height: 28px"></div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.id" size="large" @click="claimUpPage(item)" >
        <van-row type="flex" justify="space-between">
          <van-col style="font-size: 17px;" span="18">{{ item.productName }}</van-col>

          <van-col span="6" justify="end" style="text-align: right" v-if="3 == item.orderStatus">
            <van-tag size="medium" type="success">保障中</van-tag>
          </van-col>
          <van-col span="6" justify="end" style="text-align: right" v-else-if="5 == item.orderStatus">
            <van-tag size="medium" type="warning">已失效</van-tag>
          </van-col>
          <van-col span="6" justify="end" style="text-align: right" v-else-if="2 == item.orderStatus">
            <van-tag size="medium" type="warning">待生效</van-tag>
          </van-col>
          <van-col span="6" justify="end" style="text-align: right" v-else>
            <van-tag size="medium" type="primary">其他</van-tag>
          </van-col>
        </van-row>
        <p>
          <samp>
            <span v-if="item.companyCode === '1036'">服务单号</span>
            <span v-else>保单号</span>
            ：{{ item.policyNo }}
          </samp>
        </p>
        <p><samp>被保险人：{{item.insuredName}}</samp></p>
        <p><samp>保障起期：{{item.startTime}}</samp></p>
        <p><samp>保障止期：{{item.endTime}}</samp></p>
        <p><samp>投保公司：{{item.companyName}}</samp></p>
        <van-row type="flex" justify="space-between" v-if="3 == item.orderStatus || 5 == item.orderStatus">
          <van-col style="font-size: 17px;" span="18"></van-col>
          <van-col span="6" justify="end" style="text-align: right;">
            <van-tag size="large" type="primary"> 信息录入 </van-tag>
          </van-col>
        </van-row>
      </van-cell>
      <van-cell v-if="haveDraftClaim" @click="goClaimUpPageByDraftClaim">
        <van-row type="flex" style="height: 50px" justify="space-between">
          <van-col style="font-size: 17px;" span="12"></van-col>
          <van-col span="12" justify="end" style="text-align: right;">
            <van-tag size="large" type="success"> 已有未完成的理赔草稿，是否点击进入 </van-tag>
          </van-col>
        </van-row>
      </van-cell>
    </van-list>
    <van-empty v-if="empty.isShow" :image="empty.image" :description="empty.description" />
  </div>
</template>

<script>
import {getClaimPolicyList, getDraftClaimList} from "@/api/claim";

export default {
  name:"PolicyList",
  data() {
    return {
      formDate:{
        insuredCardNo:"",
        insuredName:""
      },
      list: [],
      loading: false,
      finished: false,
      haveDraftClaim: false,
      draftClaim: {},
      empty:{
        isShow:false,
        image:"default",
        description:"保单不存在",
      },
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      this.formDate.insuredCardNo = this.$route.query.insuredCardNo;
      this.formDate.insuredName = this.$route.query.insuredName;
      this.loading = true;
      getClaimPolicyList({ insuredCardNo: this.formDate.insuredCardNo, insuredName: this.formDate.insuredName, page:1, limit:10 }).then(res => {
        if (200 === res.code) {
          this.list = res.data;
          if (0 === this.list.length) {
            this.empty.isShow = true;
          }
          this.loadDraftClaimList();
        }else {
          this.empty.isShow = true;
          this.empty.description = res.result.msg;
        }
        this.finished = true;
      }).catch(()=>{
        this.finished = true;
        this.empty.isShow = true;
        this.empty.image = "network";
        this.empty.description = "请求失败";
      });
    },
    claimUpPage(item){
      if (3 == item.orderStatus || 5 == item.orderStatus) {
        this.$router.push({path: '/claim/claimInsert', query: {insuredCardNo: item.insuredCardNo, policyNo: item.policyNo}});
      }
    },
    loadDraftClaimList() {
      this.loading = true;
      this.haveDraftClaim = false;
      getDraftClaimList({ insuredCardNo: this.formDate.insuredCardNo}).then(res => {
        if (200 === res.code) {
          if (res.data.length > 0) {
            this.haveDraftClaim = true;
            this.draftClaim = res.data[0];
          }
        }
      }).catch(()=>{
        this.finished = true;
      })
    },
    goClaimUpPageByDraftClaim(){
      if (this.draftClaim && this.draftClaim.claimCode) {
        this.$router.push({path: '/claim/claimInsert', query: {claimCode: this.draftClaim.claimCode}});
      }
    },

  },
}
</script>
